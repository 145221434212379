/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import { Hero, Statement } from "../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../layouts";
import { rtfFromText } from "@bond-london/graphcms-rich-text";
import { Researchblock } from "../components/DownloadBlock";
import { GA_paragraph } from "../components/GA_paragrph";
import { GA_CardModal } from "../components/GA_cardModal";
import { GA_carousel } from "../components/GA_carousel";
import carouselImage1 from "../images/Bona.jpg";
import carouselImage2 from "../images/phailantropy.jpg";
import carouselImage3 from "../images/supporting.jpg";
import socialHead1 from "../images/social-header-bg-1.png";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: socialHead1,
      srcSet: socialHead1,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Our Social Values | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Our Social Values"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We seek to cultivate a sense of  purpose in the workplace and do good in the world."
        headline=""
        cleanedMessage={rtfFromText(
          "Our pursuit is to maximise the value we create collectively as an organisation for our clients, our people and our planet. We value the things that matter and hold strong resolve to 'do the right thing' to bring positive change across all aspects of our business and to the world we all share. By bringing opportunities and creating space for our people to do meaningful work in a way that feels authentic to them, we help them live their personal values and collectively make a lasting, positive impact."
        )}
        id={""}
        className=""
      />
      <Researchblock
        quote=""
        Title="We are honoured to be a signatory of the United Nations Global Compact a Corporate Social Responsibility initiative."
        Link="/corporate-social-responsibility"
      />
      <GA_CardModal
        id={""}
        title={"TEST"}
        content={"TESTContent"}
        Link={"URL"}
      />
      <Researchblock
        quote="'Having diversity of people, who have different ways of doing or thinking about things, is critical to coming up with the right solutions and answers to stay ahead.'"
        Title="Inclusion and Diversity Conversations"
        Link="/diversity"
        className="ga-text-color"
      />
      <GA_paragraph
        id={""}
        title="Making a difference by giving back"
        messagez="We strive to bring the strengths of our organisation and the talents and passion of our people together to create positive impact in society and help make the world a better place for all."
        className="ga-paragraph-title"
        // messageclass="ga-line-height-36"
      />
      <GA_carousel
        id={""}
        content={[
          {
            id: "1",
            title: "Pro bono work ",
            content:
              "Our pro bono efforts aim to build partnerships with a set of non-profit organisations that are aligned to our core values to help unleash their full potential have even greater impact in the communities they serve.",
            link: carouselImage1,
          },
          {
            id: "2",
            title: "Philanthropy and charity",
            content:
              "We have committed globally to a societal goal of supporting disadvantaged members of society both in actively working to increase access to renewable energy and/or assisting in upgrading infrastructure to improve sustainability, but also where our support can help bring positive change to the regions in which we live and work.",
            link: carouselImage2,
          },
          {
            id: "3",
            title: "Supporting personal activism",
            content:
              "We celebrate our people’s personal philanthropic and charitable involvement. We believe in using our voice and our platform to help bring attention to causes and organisations that do good in the world and are close to the hearts of our people.",
            link: carouselImage3,
          },
        ]}
      />
      {/* <GA_QA
        id={""}
        title={[
          {
            label: "Philanthropy and charity ",
            message:
              "We have committed globally to a societal goal of supporting disadvantaged members of society both in actively working to increase access to renewable energy and/or assisting in upgrading infrastructure to improve sustainability, but also where our support can help bring positive change to the regions in which we live and work.",
          },

          {
            id: 2,
            label: " Supporting personal activism",
            message:
              "We celebrate our people's personal philanthropic and charitable involvement. We believe in using our voice and our platform to help bring attention to causes and organisations that do good in the world and are close to the hearts of our people. ",
          },
        ]}
        message="We strive to bring the strengths of our organisation and the talents and passion of our people together to create positive impact in society and help make the world a better place for all."
        className=""
      /> */}
      {/* <GA_CTA
        id={""}
        title2="Inclusion & Diversity"
        title1="Climate Action"
        Link2="/diversity"
        Link1="/climate-action"
      /> */}
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
